import { DebugTypes } from '../actions/debug';
import update         from 'immutability-helper';

const initialState = {
    debugCount: 0,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DebugTypes.INCREASE_DEBUG_COUNTER:
            return update(state, {
                debugCount: {
                    $increaseBy: 1,
                },
            });

        case DebugTypes.RESET_DEBUG_COUNTER:
            return update(state, {
                debugCount: {
                    $set: initialState.debugCount,
                },
            });

        default:
            return state;
    }
}