import ComponentHelper from '../../../helper/ComponentHelper';
import I18n            from 'i18next';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import TeamLogo        from '../TeamLogo';

class ScoreOverlay extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.wrapper}>
                <dl>
                    {this.renderTeamRow(this.props.team1Data)}
                    {this.renderTeamRow(this.props.team2Data)}
                </dl>
                <div className={styles.imageWrapper}>
                    <img
                        alt={I18n.t('gameLogo')}
                        src={this.props.image}
                    />
                </div>
            </div>
        );
    }

    renderTeamRow = (data) => {
        if (data) {
            return (
                <>
                    <dt>
                        {this.renderTeamLogo(data)}
                        {data.name}
                    </dt>
                    <dd>
                        {data.score}
                    </dd>
                </>
            );
        }

        return null;
    };

    renderTeamLogo = (data) => {
        if (data && data.logo && data.name) {
            return (
                <TeamLogo
                    teamImage={data.logo}
                    teamName={data.name}
                />
            );
        }

        return null;
    };
}

const Component = ScoreOverlay;

Component.propTypes = {
    image:     PropTypes.string,
    team1Data: PropTypes.teamScore,
    team2Data: PropTypes.teamScore,
};

Component.defaultProps = {
    image:     '',
    team1Data: null,
    team2Data: null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;