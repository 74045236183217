const qs = require('qs');

class GameProvider {
    static getGameId () {
        const parsedQueryString = qs.parse(
            window.location.search,
            {
                ignoreQueryPrefix: true,
            },
        );

        if (parsedQueryString.spiel) {
            return parsedQueryString.spiel;
        }

        // Fallback to the first game
        return 'spiel1';
    }
}

export default GameProvider;