export default {
    ag2r:           'ag-2r.png',
    astana:         'astana.png',
    boraHansgrohe:  'bora-hansgrohe.png',
    ccc:            'ccc.png',
    cofidis:        'cofidis.png',
    creditAgricole: 'cr-dit-agricole.png',
    ineosBianchi:   'ineos-grenadier.png',
    kas:            'kas.png',
    kelme:          'kelme.png',
    lottoSoudal:    'lotto-soudal.png',
    mapei:          'mapei.png',
    movistar:       'movistar.png',
    once:           'once.png',
    orbea:          'orbea.png',
    saeco:          'saeco.png',
    tMobile:        't-mobile.png',
    telekom:        'telekom.png',
    trekSegafredo:  'trek-segafredo.png',
    usPostal:       'united-states.png',
};


