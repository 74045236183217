import ComponentHelper          from '../../../helper/ComponentHelper';
import classNames               from 'classnames';
import React                    from 'react';
import styles                   from './styles.module.scss';
import PropTypes                from '../../PropTypes';
import GameInfoOverlayAlignment from './GameInfoOverlayAlignment';

class FootballGame extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div
                className={classNames(
                    styles.wrapper,
                    {
                        [styles.wrapperLeft]:  this.props.alignment === GameInfoOverlayAlignment.left,
                        [styles.wrapperRight]: this.props.alignment === GameInfoOverlayAlignment.right,
                    },
                )}
            >
                <h2>
                    {this.props.label}
                </h2>
                <strong>
                    {this.props.value}
                </strong>
            </div>
        );
    }
}

const Component = FootballGame;

Component.propTypes = {
    alignment: PropTypes.oneOf(Object.values(GameInfoOverlayAlignment)),
    label:     PropTypes.string,
    value:     PropTypes.string,
};

Component.defaultProps = {
    alignment: GameInfoOverlayAlignment.left,
    label:     '',
    value:     '',
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;