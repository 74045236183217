import { all }        from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

const root = function* () {
    yield all([]);
};

export default {
    root,
};