import PropTypes from 'prop-types';
import TeamImage from './stateless/TeamLogo/TeamImage';

PropTypes.children = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);

PropTypes.teamScore = PropTypes.shape({
    logo:  PropTypes.oneOf(Object.values(TeamImage)),
    name:  PropTypes.string,
    score: PropTypes.number,
});

export default PropTypes;