import { MatchTypes } from '../actions/match';
import update         from 'immutability-helper';

const initialState = {
    selectedMatch: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MatchTypes.SELECT_MATCH:
            return update(state, {
                selectedMatch: {
                    $set: action.matchData,
                },
            });

        default:
            return state;
    }
}