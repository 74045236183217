import I18n                     from 'i18next';
import GameProvider             from '../helper/GameProvider';
import PageTitleHelper          from '../helper/PageTitle';
import React                    from 'react';
import DebugOverlay             from '../components/stateless/DebugOverlay';
import Table                    from '../components/stateless/Table';
import TableWrapper             from '../components/stateless/TableWrapper';
import Spacer                   from '../components/stateless/Spacer';
import { Component }            from 'react';
import { Helmet }               from 'react-helmet';
import styles                   from '../styles.module.scss';
import TableTheme               from '../components/stateless/Table/TableTheme';
import FootballGame             from '../components/stateless/FootballGame';
import FirebaseEndpoints        from '../constants/FirebaseEndpoints';
import FirebaseDataProvider     from '../helper/FirebaseDataProvider';
import FirebaseDataTransformer  from '../helper/FirebaseDataTransformer';
import { FirebaseDatabaseNode } from '@react-firebase/database';
import hash                     from 'object-hash';
import { connect }              from 'react-redux';
import { bindActionCreators }   from 'redux';
import { MatchActions }         from '../store/actions/match';
import _                        from 'lodash';
import { DebugActions }         from '../store/actions/debug';
import TableColor               from '../components/stateless/Table/TableColor';
import TabStyle                 from '../components/stateless/TableWrapper/TabStyle';
import TabColor                 from '../components/stateless/TableWrapper/TabColor';
import mobileHeader             from '../assets/images/vertragswechsel/background-mobile.jpg';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            sortColumn: 1,
            sortOrder:  'desc',
            teamData:   [],
        };
    }

    componentDidMount () {
        FirebaseDataProvider.getTeamData(this.teamDataChanged);
    }

    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <div className={styles.homeContent}>
                    <div className={styles.mobileHeaderWrapper}>
                        <img
                            src={mobileHeader}
                            alt={''}
                        />
                    </div>
                    <div className={styles.homeQualifyingTop}>
                        <TableWrapper
                            renderCallback={this.renderQualifyingQualified}
                            tabColor={TabColor.red}
                            tabStyle={TabStyle.diagonal}
                            tabTitles={[
                                I18n.t('qualifyingTitle'),
                            ]}
                            tabWidth={155}
                        />
                    </div>
                </div>
            </div>
        );
    };

    getSortStringForColumn = (column) => {
        if (this.state.sortColumn === column) {
            if (this.state.sortOrder === 'desc') {
                return ' ↓';
            }

            return ' ↑';
        }

        return '';
    };

    renderQualifyingQualified = () => {
        let sortedData = _.sortBy(
            this.state.teamData,
            'cellData[' + this.state.sortColumn + '].text',
        );

        if (this.state.sortOrder === 'desc') {
            sortedData = sortedData.reverse();
        }

        return (
            <>
                <Table
                    color={TableColor.blackTranslucent}
                    columns={[
                        {
                            title:      'Bereich' + this.getSortStringForColumn(0),
                            fontWeight: 500,
                            onClick:    () => {
                                this.setSortColumn(0);
                            },
                        },
                        {
                            title:      'Gesamt' + this.getSortStringForColumn(1),
                            fontWeight: 500,
                            onClick:    () => {
                                this.setSortColumn(1);
                            },
                        },
                        {
                            title:      'Wechsel/pb' + this.getSortStringForColumn(2),
                            fontWeight: 500,
                            onClick:    () => {
                                this.setSortColumn(2);
                            },
                        },
                    ]}
                    key={Math.random()}
                    data={sortedData}
                    fullWidthColumnIndex={0}
                    hasPaddingTop={true}
                    theme={TableTheme.small}
                />
            </>
        );
    };

    setSortColumn = (sortColumn) => {
        if (this.state.sortColumn === sortColumn) {
            const sortOrder = (
                this.state.sortOrder === 'asc' ?
                    'desc' :
                    'asc'
            );

            this.setState({
                sortOrder,
            });
        } else {
            this.setState({
                sortColumn,
            });
        }
    };

    teamDataChanged = (teamData) => {
        console.log('teamDataChanged', teamData);

        this.setState({
            teamData: FirebaseDataTransformer.transformTeamData(teamData),
        });
    };
}

const mapStateToProps = state => (
    {
        debugCount:    state.debug.debugCount,
        selectedMatch: state.match.selectedMatch,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    DebugActions,
    MatchActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);