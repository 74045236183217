import Cast from '../helper/Cast';

/**
 * @see https://docs.oracle.com/cd/E19455-01/806-0169/overview-9/index.html
 */
class NumberFormat {
    static addLeadingZero (value, expectedStringLength) {
        const temporaryValue = '0'.repeat(expectedStringLength) + Cast.string(value);
        const finalValue     = temporaryValue.slice(expectedStringLength * -1);

        return finalValue;
    }

    static getValueString (value) {
        const floatValue  = Cast.float(value, 2);
        const fixedValue  = floatValue.toFixed(2);
        const valueString = Cast.string(fixedValue);

        return valueString;
    }

    static toEnglishPunctuation (value) {
        const valueString      = NumberFormat.getValueString(value);
        const valueStringComma = valueString.replace('.', ',');

        return valueStringComma;
    }

    static toGermanPunctuation (value) {
        const valueString    = NumberFormat.getValueString(value);
        const valueStringDot = valueString.replace(',', '.');

        return valueStringDot;
    }
}

export default NumberFormat;