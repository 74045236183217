import { makeActionCreator } from '../../helper/Store';

export const DebugTypes = {
    INCREASE_DEBUG_COUNTER: 'Debug/INCREASE_DEBUG_COUNTER',
    RESET_DEBUG_COUNTER:    'Debug/RESET_DEBUG_COUNTER',
};

const increaseDebugCounter = makeActionCreator(
    DebugTypes.INCREASE_DEBUG_COUNTER,
);

const resetDebugCounter = makeActionCreator(
    DebugTypes.RESET_DEBUG_COUNTER,
);

export const DebugActions = {
    increaseDebugCounter,
    resetDebugCounter,
};