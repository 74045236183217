// TODO-Skeleton start
// TODO: https://lulububu.atlassian.net/browse/TAURESSALGAM-4
import I18n            from 'i18next';
import PageTitleHelper from '../helper/PageTitle';
import React           from 'react';
import { Component }   from 'react';
import { Helmet }      from 'react-helmet';

class Screen extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('pageNotFound'))}
                    </title>
                </Helmet>
                <h1>
                    {I18n.t('error_404')}
                </h1>
                <p>
                    {I18n.t('pageNotFound')}
                </p>
            </div>
        );
    }
}

export default Screen;
// TODO-Skeleton end