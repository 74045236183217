import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import classNames      from 'classnames';
import Cast            from '../../../helper/Cast';
import PrettyScrollBar from '../PrettyScrollBar';
import TabStyle        from './TabStyle';
import TabColor        from './TabColor';
import ResizeListener  from '../ResizeListener';

class TableWrapper extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };
    }

    getTabWidthInPixel = () => {
        const tabWidthInPixel = Cast.pixelString(this.props.tabWidth);

        return tabWidthInPixel;
    };

    hasMoreThanOneTab = () => {
        return this.props.tabTitles.length > 1;
    };

    render () {
        let inner = (
            <div className={styles.contentWrapper}>
                {this.renderContent()}
            </div>
        );

        if (this.props.windowWidth > 992) {
            inner = (
                <PrettyScrollBar>
                    {inner}
                </PrettyScrollBar>
            );
        }

        return (
            <div className={styles.wrapper}>
                {inner}
            </div>
        );
    }

    renderContent = () => {
        if (this.props.renderCallback) {
            return this.props.renderCallback(this.state.activeTabIndex);
        }

        return null;
    };

    renderTabs = () => {
        const width = this.props.tabTitles.length * this.props.tabWidth;

        return (
            <div
                className={classNames(
                    styles.tabs,
                    {
                        [styles.tabsDiagonal]: this.props.tabStyle === TabStyle.diagonal,
                        [styles.tabsRound]:    this.props.tabStyle === TabStyle.round,
                        [styles.tabsBlack]:    this.props.tabColor === TabColor.black,
                        [styles.tabsRed]:      this.props.tabColor === TabColor.red,
                        [styles.tabsWhite]:    this.props.tabColor === TabColor.white,
                    },
                )}
                style={{
                    width: Cast.pixelString(width),
                }}
            >
                {this.renderTabUnderlay()}
                {this.props.tabTitles.map(this.renderTabTitle)}
            </div>
        );
    };

    renderTabTitle = (tabTitle, index) => {
        return (
            <div
                className={classNames(
                    styles.tab,
                    {
                        [styles.tabActive]:       this.state.activeTabIndex === index,
                        [styles.tabNotClickable]: !this.hasMoreThanOneTab(),
                        [styles.tabDiagonal]:     this.props.tabStyle === TabStyle.diagonal,
                        [styles.tabRound]:        this.props.tabStyle === TabStyle.round,
                        [styles.tabBlack]:        this.props.tabColor === TabColor.black,
                        [styles.tabRed]:          this.props.tabColor === TabColor.red,
                        [styles.tabWhite]:        this.props.tabColor === TabColor.white,
                    },
                )}
                key={'tabTitle_' + index}
                onClick={() => {
                    this.tabClicked(index);
                }}
                style={{
                    width: this.getTabWidthInPixel(),
                }}
            >
                {tabTitle}
            </div>
        );
    };

    renderTabUnderlay = () => {
        const left = this.state.activeTabIndex * this.props.tabWidth;

        return (
            <div
                className={classNames(
                    styles.tabUnderlay,
                    {
                        [styles.tabUnderlayDiagonal]: this.props.tabStyle === TabStyle.diagonal,
                        [styles.tabUnderlayRound]:    this.props.tabStyle === TabStyle.round,
                        [styles.tabUnderlayBlack]:    this.props.tabColor === TabColor.black,
                        [styles.tabUnderlayRed]:      this.props.tabColor === TabColor.red,
                        [styles.tabUnderlayWhite]:    this.props.tabColor === TabColor.white,
                    },
                )}
                style={{
                    left:  Cast.pixelString(left),
                    width: this.getTabWidthInPixel(),
                }}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return true;
    }

    tabClicked = (tabIndex) => {
        if (this.hasMoreThanOneTab()) {
            this.setState({
                activeTabIndex: tabIndex,
            });
        }
    };
}

const Component = TableWrapper;

Component.propTypes = {
    renderCallback: PropTypes.func,
    tabColor:       PropTypes.oneOf(TabColor),
    tabStyle:       PropTypes.oneOf(TabStyle),
    tabTitles:      PropTypes.array,
    tabWidth:       PropTypes.number,
    windowHeight:   PropTypes.number,
    windowWidth:    PropTypes.number,
};

Component.defaultProps = {
    renderCallback: null,
    tabColor:       TabColor.black,
    tabStyle:       TabStyle.round,
    tabTitles:      [],
    tabWidth:       100,
    windowHeight:   null,
    windowWidth:    null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [
    'activeTabIndex',
];

export default ResizeListener(Component);