import React                    from 'react';
import styles                   from './styles.module.scss';
import PropTypes                from '../../PropTypes';
import GameInfoOverlay          from '../GameInfoOverlay';
import GameInfoOverlayAlignment from '../GameInfoOverlay/GameInfoOverlayAlignment';
import I18n                     from 'i18next';
import moment                   from 'moment';
import ComponentHelper          from '../../../helper/ComponentHelper';

class CurrentTimeGameInfoOverlay extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentTimeString: '0',
        };
    }

    componentDidMount () {
        setInterval(
            () => {
                this.setState({
                    currentTimeString: moment().format('HH:mm'),
                });
            },
            1000,
        );
    }

    render () {
        return (
            <GameInfoOverlay
                alignment={GameInfoOverlayAlignment.right}
                label={I18n.t('time')}
                value={this.state.currentTimeString}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CurrentTimeGameInfoOverlay;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = ['currentTimeString'];

export default Component;