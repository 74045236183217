import ComponentHelper     from '../../../helper/ComponentHelper';
import I18n                from 'i18next';
import React               from 'react';
import styles              from './styles.module.scss';
import HeaderLogo          from '../../../assets/images/tour-de-france-logo.svg';
import F1Logo              from '../../../assets/images/f1-logo-red.svg';
import TauResLogoWhite     from '../../../assets/images/taures-logo-white.svg';
import CustomCountDown     from '../CustomCountDown';
import VersionNumber       from '../VersionNumber';
import DebugCounterWrapper from '../../connected/DebugCounterWrapper';

class Header extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.left}>

                    </div>
                    <div className={styles.center}>
                        <div className={styles.headerBadge}>
                            Vermittlerwechsel
                        </div>
                    </div>
                    <div className={styles.right}>
                        <DebugCounterWrapper>
                            <VersionNumber />
                        </DebugCounterWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

const Component = Header;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;