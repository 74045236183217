//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ComponentHelper from '../../../helper/ComponentHelper';
import { Scrollbars }  from 'react-custom-scrollbars';

class PrettyScrollBar extends React.Component {
    render () {
        return (
            <Scrollbars
                autoHide={true}
                hideTracksWhenNotNeeded={true}
                renderThumbVertical={this.renderThumbVertical}
            >
                {this.props.children}
            </Scrollbars>
        );
    }

    renderThumbVertical = (props) => {
        return (
            <div
                {...props}
                className={styles.thumbVertical}
            />
        );
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = PrettyScrollBar;

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;