import _ from 'lodash';

class Cast {
    static array (value) {
        if (!_.isArray(value)) {
            return [value];
        }

        return value;
    }

    static bool (value) {
        const castedValue = !!value;

        return castedValue;
    }

    static float (value) {
        if (_.isString(value)) {
            value = value.replace(/[^0-9.]/g, '.');
        }

        const castedValue = parseFloat(value) || 0;

        return castedValue;
    }

    static int (value) {
        const castedValue = parseInt(value, 10) || 0;

        return castedValue;
    }

    static pixelString (value) {
        const castedValue = value + 'px';

        return castedValue;
    }

    static string (value) {
        const castedValue = value + '';

        return castedValue;
    }
}

export default Cast;