import React     from 'react';
import styles    from './styles.module.scss';
import PropTypes from '../../PropTypes';

class Component extends React.Component {
    getStyle = () => {
        const style = {};

        if (this.props.height) {
            style.height = this.props.height;
        }

        if (this.props.width) {
            style.width = this.props.width;
        }

        return style;
    };

    render () {
        const style = this.getStyle();

        return (
            <span
                className={styles.spacer}
                style={style}
            >

            </span>
        );
    }
}

Component.propTypes = {
    height: PropTypes.number,
    width:  PropTypes.number,
};

Component.defaultProps = {
    height: 0,
    width:  0,
};

export default Component;