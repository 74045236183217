import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import TeamImage       from './TeamImage';
import SelectionHelper from '../../../helper/SelectionHelper';

// Team images
import ag2rImage           from '../../../assets/images/teams-tour-de-france/ag-2r.png';
import astanaImage         from '../../../assets/images/teams-tour-de-france/astana.png';
import boraHansgroheImage  from '../../../assets/images/teams-tour-de-france/bora-hansgrohe.png';
import cccImage            from '../../../assets/images/teams-tour-de-france/ccc.png';
import cofidisImage        from '../../../assets/images/teams-tour-de-france/cofidis.png';
import creditAgricoleImage from '../../../assets/images/teams-tour-de-france/cr-dit-agricole.png';
import ineosBianchiImage   from '../../../assets/images/teams-tour-de-france/ineos-grenadier.png';
import kasImage            from '../../../assets/images/teams-tour-de-france/kas.png';
import kelmeImage          from '../../../assets/images/teams-tour-de-france/kelme.png';
import lottoSoudalImage    from '../../../assets/images/teams-tour-de-france/lotto-soudal.png';
import mapeiImage          from '../../../assets/images/teams-tour-de-france/mapei.png';
import movistarImage       from '../../../assets/images/teams-tour-de-france/movistar.png';
import onceImage           from '../../../assets/images/teams-tour-de-france/once.png';
import orbeaImage          from '../../../assets/images/teams-tour-de-france/orbea.png';
import saecoImage          from '../../../assets/images/teams-tour-de-france/saeco.png';
import tMobileImage        from '../../../assets/images/teams-tour-de-france/t-mobile.png';
import telekomImage        from '../../../assets/images/teams-tour-de-france/telekom.png';
import trekSegafredoImage  from '../../../assets/images/teams-tour-de-france/trek-segafredo.png';
import usPostalImage       from '../../../assets/images/teams-tour-de-france/united-states.png';

class Component extends React.Component {

    render () {
        return (
            <div className={styles.wrapper}>
                {this.renderTeamImage()}
            </div>
        );
    }

    renderTeamImage = () => {
        const imageResource = SelectionHelper.get(
            this.props.teamImage,
            {
                [TeamImage.ag2r]:           ag2rImage,
                [TeamImage.astana]:         astanaImage,
                [TeamImage.boraHansgrohe]:  boraHansgroheImage,
                [TeamImage.ccc]:            cccImage,
                [TeamImage.cofidis]:        cofidisImage,
                [TeamImage.creditAgricole]: creditAgricoleImage,
                [TeamImage.ineosBianchi]:   ineosBianchiImage,
                [TeamImage.kas]:            kasImage,
                [TeamImage.kelme]:          kelmeImage,
                [TeamImage.lottoSoudal]:    lottoSoudalImage,
                [TeamImage.mapei]:          mapeiImage,
                [TeamImage.movistar]:       movistarImage,
                [TeamImage.once]:           onceImage,
                [TeamImage.orbea]:          orbeaImage,
                [TeamImage.saeco]:          saecoImage,
                [TeamImage.tMobile]:        tMobileImage,
                [TeamImage.telekom]:        telekomImage,
                [TeamImage.trekSegafredo]:  trekSegafredoImage,
                [TeamImage.usPostal]:       usPostalImage,
            },
        );

        return (
            <img
                alt={this.props.teamName}
                src={imageResource}
            />
        );
    };
}

Component.propTypes = {
    teamImage: PropTypes.oneOf(Object.values(TeamImage)),
    teamName:  PropTypes.string,
};

Component.defaultProps = {
    teamImage: null,
    teamName:  null,
};

export default Component;