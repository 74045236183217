import React     from 'react';
import styles    from './styles.module.scss';
import PropTypes from '../../PropTypes';

class Component extends React.Component {
    render () {
        return (
            <div className={styles.wrapper}>
                {this.props.children}
            </div>
        );
    }
}

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;