import { makeActionCreator } from '../../helper/Store';

export const MatchTypes = {
    SELECT_MATCH: 'Match/SELECT_MATCH',
};

const selectMatch = makeActionCreator(
    MatchTypes.SELECT_MATCH,
    {
        matchData: null,
    },
);

export const MatchActions = {
    selectMatch,
};