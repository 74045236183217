import ComponentHelper        from '../../../helper/ComponentHelper';
import React                  from 'react';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { DebugActions }       from '../../../store/actions/debug';
import PropTypes              from '../../PropTypes';

class DebugCounterWrapper extends React.Component {
    render () {
        return (
            <div onClick={this.wrapperClicked}>
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    wrapperClicked = () => {
        this.props.increaseDebugCounter();
    };
}

const Component = DebugCounterWrapper;

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: null,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

const mapDispatchToProps = dispatch => bindActionCreators(DebugActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);