import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import ClearCache      from 'react-clear-cache';
import ComponentHelper from '../../../helper/ComponentHelper';

class CacheInvalidator extends React.Component {
    render () {
        return (
            <ClearCache
                auto={true}
                duration={1000 * 60 * 30}
            >
                {({ isLatestVersion, emptyCacheStorage }) => {
                    // This cannot be empty otherwise react-clear-cache will crash
                    return '';
                }}
            </ClearCache>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CacheInvalidator;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

Component.defaultProps = {};

export default Component;