import ComponentHelper            from '../../../helper/ComponentHelper';
import I18n                       from 'i18next';
import React                      from 'react';
import styles                     from './styles.module.scss';
import CurrentTimeGameInfoOverlay from '../CurrentTimeGameInfoOverlay';
import GameInfoOverlay            from '../GameInfoOverlay';
import Cast                       from '../../../helper/Cast';
import GameProvider               from '../../../helper/GameProvider';
import GameInfoOverlayAlignment   from '../GameInfoOverlay/GameInfoOverlayAlignment';
import ScoreOverlay               from '../ScoreOverlay';
import classNames                 from 'classnames';
import PropTypes                  from '../../PropTypes';
import GameForeground             from '../../../assets/images/game-foreground.png';
import TeamImage                  from '../TeamLogo/TeamImage';

class FootballGame extends React.Component {
    getGameDay = () => {
        const game            = GameProvider.getGameId();
        const gameDayInternal = game.replace('spiel', '');
        const gameDay         = Cast.int(gameDayInternal);

        return gameDay;
    };

    render () {

        const playerLeft = (
            <div
                className={classNames(
                    styles.player,
                    styles.playerLeft,
                )}
            />
        );

        const playerRight = (
            <div
                className={classNames(
                    styles.player,
                    styles.playerRight,
                )}
            />
        );

        return (
            <div className={styles.wrapper}>
                <GameInfoOverlay
                    alignment={GameInfoOverlayAlignment.left}
                    label={I18n.t('gameday')}
                    value={this.getGameDay()}
                />
                <CurrentTimeGameInfoOverlay />
                <ScoreOverlay
                    image={require('../../../assets/images/championsLeague.svg')}
                    team1Data={{
                        logo:  this.props.team1Logo,
                        name:  this.props.team1Name,
                        score: this.props.team1Score,
                    }}
                    team2Data={{
                        logo:  this.props.team2Logo,
                        name:  this.props.team2Name,
                        score: this.props.team2Score,
                    }}
                />
                <div className={styles.background}>
                    <div
                        className={classNames(
                            styles.foreground,
                            {
                                [styles.gameStateTeam1State0]: this.props.team1StateIndex === 0,
                                [styles.gameStateTeam1State1]: this.props.team1StateIndex === 1,
                                [styles.gameStateTeam1State2]: this.props.team1StateIndex === 2,
                                [styles.gameStateTeam1State3]: this.props.team1StateIndex === 3,
                                [styles.gameStateTeam1State4]: this.props.team1StateIndex === 4,
                                [styles.gameStateTeam1State5]: this.props.team1StateIndex === 5,
                                [styles.gameStateTeam2State0]: this.props.team2StateIndex === 0,
                                [styles.gameStateTeam2State1]: this.props.team2StateIndex === 1,
                                [styles.gameStateTeam2State2]: this.props.team2StateIndex === 2,
                                [styles.gameStateTeam2State3]: this.props.team2StateIndex === 3,
                                [styles.gameStateTeam2State4]: this.props.team2StateIndex === 4,
                                [styles.gameStateTeam2State5]: this.props.team2StateIndex === 5,
                            },
                        )}
                    >
                        <img
                            src={GameForeground}
                        />
                        <div
                            className={classNames(
                                styles.ball,
                                styles.ballTeamLeft,
                            )}
                        >
                            <span>
                                {this.props.team1ShortName}
                            </span>
                        </div>
                        <div
                            className={classNames(
                                styles.ball,
                                styles.ballTeamRight,
                            )}
                        >
                            <span>
                                {this.props.team2ShortName}
                            </span>
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole1,
                            )}
                        >
                            {playerLeft}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole2,
                            )}
                        >
                            {playerLeft}
                            {playerLeft}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole3,
                            )}
                        >
                            {playerRight}
                            {playerRight}
                            {playerRight}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole4,
                            )}
                        >
                            {playerLeft}
                            {playerLeft}
                            {playerLeft}
                            {playerLeft}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole5,
                            )}
                        >
                            {playerRight}
                            {playerRight}
                            {playerRight}
                            {playerRight}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole6,
                            )}
                        >
                            {playerLeft}
                            {playerLeft}
                            {playerLeft}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole7,
                            )}
                        >
                            {playerRight}
                            {playerRight}
                        </div>
                        <div
                            className={classNames(
                                styles.pole,
                                styles.pole8,
                            )}
                        >
                            {playerRight}
                        </div>
                        <div className={styles.stadion} />
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = FootballGame;

Component.propTypes = {
    team1Logo:       PropTypes.oneOf(Object.values(TeamImage)),
    team1Name:       PropTypes.string,
    team1Score:      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    team1ShortName:  PropTypes.string,
    team1StateIndex: PropTypes.number,
    team2Logo:       PropTypes.oneOf(Object.values(TeamImage)),
    team2Name:       PropTypes.string,
    team2Score:      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    team2ShortName:  PropTypes.string,
    team2StateIndex: PropTypes.number,
};

Component.defaultProps = {
    team1Logo:       null,
    team1Name:       '',
    team1Score:      0,
    team1ShortName:  '',
    team1StateIndex: 0,
    team2Logo:       null,
    team2Name:       '',
    team2Score:      0,
    team2ShortName:  '',
    team2StateIndex: 0,
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;